<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid"/> Cancelation Reasons Stats
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable
            :itemsPerPage="15"
            border
            :loading="isLoading"
            :items="sortedCancellationReasons"
            :fields="cancellationReasonsColumns">

          <template #loading>
            <CElementCover
              :boundaries="[
                { sides: ['top'], query: 'td' },
                { sides: ['bottom'], query: 'tbody' }
              ]"
            >
              <h1 class="d-inline">Loading... </h1><CSpinner size="5xl" color="info"/>
            </CElementCover>
          </template>
        </CDataTable>

      </CCardBody>
    </CCard>

    <CCard>
      <CCardBody>
        <CChartLine
            :datasets="datasets"
            :labels="labels"
            :options="chartOptions"
        />

        <CElementCover v-if="isLoading">
          <CSpinner size="5xl" color="info"/>
        </CElementCover>

      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <CChartLine
            :datasets="datasetsDiff"
            :labels="labels"
            :options="chartOptions"
        />

        <CElementCover v-if="isLoading">
          <CSpinner size="5xl" color="info"/>
        </CElementCover>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import axios from "axios";
import dayjs from 'dayjs'
import { Auth } from "aws-amplify";

export default {
  name: 'Stats',
  components: { CChartLine },
  data() {
    return {
      isLoading: false,

      // Table
      cancellationReasonsColumns: [
        {key: 'reason', label: 'Reason'},
        {key: 'count', label: 'Count'},
      ],
      cancellationReasons: [],

      // Chart
      datasets: [],
      datasetsDiff: [],
      labels: [],

      chartOptions: {
        // responsive: true,
        // maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              stepSize: 2
            },
            gridLines: {
              zeroLineColor: "#444",
              zeroLineWidth: 1
            },
          }],
        }
      }
    }
  },
  methods: {
    async loadData() {
      try {

        this.isLoading = true;

        const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
        const response = await axios.get(
          process.env.VUE_APP_MY_API_URL + '/sub/stats',
          {
            headers: {
              Authorization: jwtToken
            },
            params: {}
          }
        );

        this.updateChartData(response.data);
        this.cancellationReasons = response.data.cancellationReasons;

      } catch (error) {
        if (error.response) {
          this.$toast.error(error.response.status + ': ' + error.response.data.message);
        } else {
          this.$toast.error(error.message);
        }
      } finally {
        this.isLoading = false;
      }
    },
    updateChartData(data) {

      if(!data) return;

      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 60);
      const dates = this.getDatesInterval(startDate, new Date());

      const labels = [];
      const subCancelled = {
        label: 'Cancelled',
        borderColor: '#dc3912',
        backgroundColor: 'rgba(220, 57, 18, .2)',
        fill: true,
        data: []
      };
      const subActive = {
        label: 'Activated',
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, .2)',
        fill: true,
        data: []
      };
      const subSuspended = {
        label: 'Suspended',
        borderColor: 'rgb(146,146,146)',
        backgroundColor: 'rgba(146,146,146, .2)',
        fill: true,
        data: []
      };
      const subDiff = {
        label: 'Total',
        borderColor: 'rgba(154, 104, 255, 1)',
        backgroundColor: 'rgba(154, 104, 255, 1)',
        fill: false,
        data: [],
      };

      let dayjsDate;
      const dataset_active = {};
      const dataset_cancelled = {};
      const dataset_suspended = {};
      dates.forEach(date => {
        dayjsDate = dayjs(date);
        labels.push(dayjsDate.format('MMM DD'));

        dataset_active[dayjsDate.format('MM-DD')] = 0;
        dataset_cancelled[dayjsDate.format('MM-DD')] = 0;
        dataset_suspended[dayjsDate.format('MM-DD')] = 0;
      })

      data.items.active.at.forEach((timestamp, i) => {
        const dayjsDate = dayjs.unix(timestamp);
        dataset_active[dayjsDate.format('MM-DD')] = data.items.active.count[i];
      });

      data.items.cancelled.at.forEach((timestamp, i) => {
        const dayjsDate = dayjs.unix(timestamp);
        dataset_cancelled[dayjsDate.format('MM-DD')] = data.items.cancelled.count[i];
      });

      data.items.suspended.at.forEach((timestamp, i) => {
        const dayjsDate = dayjs.unix(timestamp);
        dataset_suspended[dayjsDate.format('MM-DD')] = data.items.suspended.count[i];
      });

      subCancelled.data = Object.values(dataset_cancelled);
      subActive.data = Object.values(dataset_active);
      subSuspended.data = Object.values(dataset_suspended);

      dates.forEach(date => {
        dayjsDate = dayjs(date);
        const dateMMDD = dayjsDate.format('MM-DD');

        const diff = dataset_active[dateMMDD] - dataset_cancelled[dateMMDD] - dataset_suspended[dateMMDD];
        subDiff.data.push(diff);
      })

      this.labels = labels;

      this.datasets = [
        subCancelled,
        subSuspended,
        subActive,
      ]

      this.datasetsDiff = [
        subDiff
      ]
    },
    getDatesInterval(startDate, endDate) {
      const dates = []
      let currentDate = startDate
      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }
      while (currentDate <= endDate) {
        dates.push(currentDate)
        currentDate = addDays.call(currentDate, 1)
      }
      return dates
    }
  },
  computed: {
    sortedCancellationReasons() {
      return this.cancellationReasons.sort((a, b) => {
        return b.count - a.count;
      })
    }
  },
  async mounted() {
    await this.loadData();
  }
}
</script>
